
.rc-color-picker-trigger {

	width: 20px !important;
	vertical-align: middle !important;
	border-radius: 20px !important;
	border: 1px solid #949494 !important;
	box-shadow: none !important;
	margin-right: 10px;
	margin-left: 5px;
}


.formPHIcon {

    vertical-align: 0.125em !important;
	margin-right: 5px !important;
}


.chooseFileButton {

	border-radius: 5px !important;
}


.modal-header {

    border-bottom: 0 !important;
}


.modal-footer {

    border-top: 0 !important;
}
