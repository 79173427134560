.html-textarea
{
    height: 250px !important;
}

.drafjs-editor
{
    border: 1px solid #f1f1f1;
    border-radius: 2px;
}

.DraftEditor-content
{
    height: 250px;
}
