#signInTitle {

	text-align: center;
	margin-top: 1.5rem !important;
	margin-bottom: 3rem !important;
}


#signIn {

	text-align: center;
	margin-top: 3rem;
}


.signInButton {

	width: 100px;
}


.signInIcon {

	margin-right: 10px;
	font-size: 13px;
}


.loginCard {

	border-radius: 0;
	margin-top: 10%;
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}


.loginFormGroup {

	width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.centeredRow
{
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
  	
	margin: auto;
}

.credits
{
	position: absolute;
	bottom: 10px;
  	left: 50%;
	transform: translate(-50%, 0);
	
	margin: auto;
}