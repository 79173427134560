#underlay {
    display: inline-block;
    position: relative;
}

#underlay > .markers {
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
}

#underlay > .markers > .marker {
    position: absolute;
	cursor: pointer;
	color: #26B99A;
	border: 0;
	height: 45px;
	width: 75px;

	-webkit-transform: translateX(-50%) translateY(-50%);
       -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
         -o-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}

#underlay > .markers > .marker:hover {
	-webkit-transform: translateX(-50%) translateY(-50%) scale(1.1);
	   -moz-transform: translateX(-50%) translateY(-50%) scale(1.1);
		-ms-transform: translateX(-50%) translateY(-50%) scale(1.1);
		 -o-transform: translateX(-50%) translateY(-50%) scale(1.1);
			transform: translateX(-50%) translateY(-50%) scale(1.1);

	z-index: 1000;
}

.markerBeingPlaced {
	color: #329CC3;

	position: absolute;
	height: 30px;
	width: 30px;

	animation: blinkMarkerBeingPlaced 2.5s;
	animation-iteration-count: infinite;
}

.popup-content {
	background: rgba(255, 255, 255, 0.7) !important;
	border-radius: 20px !important;
}

@keyframes blink
{
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes blinkMarkerBeingPlaced {
	0% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1);
	       -moz-transform: translateX(-50%) translateY(-70%) scale(1);
	        -ms-transform: translateX(-50%) translateY(-70%) scale(1);
	         -o-transform: translateX(-50%) translateY(-70%) scale(1);
	            transform: translateX(-50%) translateY(-70%) scale(1);
	}
	50% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1.5);
	       -moz-transform: translateX(-50%) translateY(-70%) scale(1.5);
	        -ms-transform: translateX(-50%) translateY(-70%) scale(1.5);
	         -o-transform: translateX(-50%) translateY(-70%) scale(1.5);
	            transform: translateX(-50%) translateY(-70%) scale(1.5);
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1);
	       -moz-transform: translateX(-50%) translateY(-70%) scale(1);
	        -ms-transform: translateX(-50%) translateY(-70%) scale(1);
	         -o-transform: translateX(-50%) translateY(-70%) scale(1);
	            transform: translateX(-50%) translateY(-70%) scale(1);
	}
}

.addButton {
	position: absolute;
	bottom: 50px;
	right: 80px;

	cursor: pointer;
	height: 45px !important;
	width: 45px !important;
	opacity: 0.5;
	color: #329CC3;

	border-radius: 70px;
	background-color: transparent;
	border-width: 0 !important;
	cursor:pointer;

	transition: .2s ease-in;
	animation: blink 2s;
	animation-iteration-count: infinite;
}

.addButton:hover {
	opacity: 1;

	-webkit-animation-play-state:paused;
	-moz-animation-play-state:paused;
	-o-animation-play-state:paused;
	animation-play-state:paused;
}

.smallAddButton {
	height: 35px !important;
	width: 35px !important;
}

.marker-container {
    overflow: hidden;
}

.sitemap-content {
	text-align: center;
}