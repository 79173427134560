
.geo-tracking-table-container
{
	width: 100%;
}

.editable-cell
{
    position: relative;
}

.editable-cell-value-wrap
{
	padding: 5px 12px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
}
