
[data-title].inactiveDeviceCard:hover::before {

  content: attr(data-title);
  width: 200px;
  position: absolute;
  bottom: 50px;
  right: -60px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 5px;
  background-color: rgba(255, 83, 105, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: pre-line;
}


[data-title].activeDeviceCard:hover::before {

	content: attr(data-title);
    width: 200px;
    position: absolute;
	bottom: 50px;
    right: -60px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: rgba(40, 167, 69, 0.85);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
	white-space: pre-line;
}


.configStatusMarker {

	margin: auto;
}


.viewStatusMarker {

	margin: auto;
	background: white;
	border-radius: 30px;
	padding: 4px;
	font-size: 25px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(.25, .8, .25, 1);
}


.viewStatusMarker:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}


.sitemapValueSnippetDiv
{
    position: absolute;
	max-width: 65px;
	height: 20px;
	padding: 5px;
	border-radius: 5px;
	line-height: 100%;
	text-align: center;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
	color: black;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
	font: 400 9px Roboto, Arial, sans-serif;
}


.deviceMarkerSpan
{
	position: absolute;
	left: 25px;
	top: 18px;
}


.deviceMarkerSpanView{
    position: absolute;
	background-color: rgba(255, 255, 255, 0.95);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}
