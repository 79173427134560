.CLASSIC_VALUE_CONTENT, .BOOLEAN_CONTENT, .INDEX_CONTENT {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-grow: 1;
}


.value-content {
    flex-grow: 1;
}


.title {

    font-size: 1.2em;
    font-weight: bold;
}


.value {
    flex-grow: 1;
    font-size: 1.5em;
	margin: 15px 0 15px 0;
}


.date {

    font-style: italic;
}

.DWG_DIS_COL-2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
}

.DWG_DIS_COL-3 {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
}