.widget {
    color: #73879C;
    height: 100%;
}


.widget-container {

	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}


.widget-container:hover {

	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}


.widget-config {
	padding-bottom: 15px;
    text-align: right;
	/* text-overflow: ellipsis;
	overflow: hidden; */
	white-space: nowrap;
}

.widget-block {
	padding-bottom: 15px;
    text-align: right;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.widget-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.widget-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.left-title {
    flex-direction: row;
    align-items: center;
}

.left-title .HEADER {
    padding-right: 5px;
}

.center-title .HEADER
{
    text-align: center;
}

.sitemap {
	padding: 0 !important;
}

.widget-title {
    text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
    font-weight: 450;
}


.widget-icon {

    font-size: 0.85em;
    margin-right: 0.5em;
    cursor: pointer;
    font-weight: 450;
}


.height-100 {

    height: 100%;
}


.modal-content {

    padding: 5px;
}


.wideModal {
    max-width: 60% !important;
}


.wideModal .modal-content {
    padding: 20px !important;
}


.fadedOutFormGroup {
    opacity: 0.6;
}


.fadedOutFormGroup:hover {
    opacity: 1;
}


.ant-notification {
	z-index: 9999;
}


.ant-popover {
	z-index: 9999;
}


.noData {
	text-align: center;

	-webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}


.ant-btn .anticon {
    vertical-align: 0.125em;
}


.shadowedIcon {
	border-color: transparent !important;
}


.shadowedIcon:hover, .shadowedIcon-selected {
	border-color: transparent !important;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}


.shadowedIcon:hover {
	transform: scale(1.2);
	-ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
}


@keyframes pulsate {
	from { opacity: 1; }
    50% { opacity: 0.05; }
    to { opacity: 1; }
}


.pulsating-tag {
	transition: .3s ease-in;
	animation: pulsate 2s;
	animation-iteration-count: infinite;
}


.ant-popover-inner-content {
	overflow-y: auto;
	max-height: 80vh;
}
