
.themeSuccessAlert {

	width: 100%;
	text-align: center;
}


.themeBuilderSpinner {

	margin-left: auto !important;
	margin-right: auto !important;
	margin-top: 20px !important;
}


.largeCol {

	padding-left: 0px !important;
	padding-right: 0px !important;
}
