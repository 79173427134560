.confButton {
	bottom: 50px;
	right: 80px;
    
	cursor: pointer;
	height: 45px !important;
	width: 45px !important;
	opacity: 0.5;
	color: #329CC3;
    
	border-radius: 70px;
	background-color: transparent;
	border-width: 0 !important;
	cursor:pointer;
    
	transition: .2s ease-in;
	animation: blink 2s;
	animation-iteration-count: infinite;
}

.smallConfButton {
    height: 35px !important;
    width: 35px !important;
}
