
.tableContainer {
	width: 100%;
	padding-left: 15px;
    padding-right: 15px;
}


.editable-cell {
    position: relative;
}


.editable-cell-value-wrap {
	padding: 5px 12px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
}