/* NOTE: the order of these styles DO matter */

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #26B99A;
    border: 1px solid #169F85;
    color: white;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
    background: #169F85;
    color: white;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
    background: #169F85;
    color: white;
}

/* Will edit when the second date (end date) in a range of dates */
/* is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #26B99A;
}
