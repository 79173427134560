#toolBar {

  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 1000;
}


.toolbar-item {

    font-size: 1.05em;
    margin-left: 1em;
    cursor: pointer;
    background: transparent;
	color: white;
    padding: 0.2em 0.75em;
    border-radius: 5px;
}


.toolbar-item:hover {

	-webkit-transform: scale(1.2);
	   -moz-transform: scale(1.2);
		-ms-transform: scale(1.2);
		 -o-transform: scale(1.2);
			transform: scale(1.2);
}


.toolbar-item-mobile {

    cursor: pointer;
}


.toolbar-container {

    padding: 0.5rem 1rem !important;
	background: linear-gradient(to right, #3282b8, #38c6bd);
 	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}


.toolbar-separator {

    font-size: 1.2em;
    margin-left: 1em;
    cursor: default;
    font-weight: 600;
    padding-top: 3px;
}


.toolbar-separator-mobile {

    background: #515356;
    height: 1px;
    width: 100%;
}


#actionIcons {

	margin: auto !important;
}


#refreshAll {

	margin-left: -25px !important;
	margin-right: 0 !important;
	width: 0 !important;
}
