#status-text 
{
	margin-top: 10px;
    text-align: center;
}

.fa-gradient 
{
	background: -webkit-gradient(linear, left top, left bottom, from(#f00), to(#333));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

#video {
	margin-top: 30px;
}

#buttons {
	position: absolute;
	bottom:   0;
	left: 0;
	right: 0;
	padding-bottom: 10px;
	
}

#buttons > .ant-switch{
	margin-left: 80px;
	margin-right: 80px;
}