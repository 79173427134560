.config-bar-item {

    color: #fff;
    cursor: pointer;
}


.widget-container {

    overflow: hidden;
}


.modalButton {

	width: 130px;
}


.ant-tooltip-inner {

	text-align: center !important;
    display: block;
    border-radius: 5px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 12px;
    font-family: sans-serif;
	white-space: pre-line;
}


.ant-tooltip
{
	z-index: 9999;
}


.ant-popover-buttons {

    text-align: center !important;
}


.pageLoader {

    position: absolute;
    top: 48%;
    left: 48%;
}


.ant-menu {

	background: transparent !important;
	border-bottom: 0;
}


.ant-menu-submenu > .ant-menu {

    padding-bottom: 5px;
}


.ant-menu-item, .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {

	top: -.5px !important;
}


.ant-menu-item, .ant-menu-submenu-title {

	padding: 0 15px !important;
}


.menuIcon {

	vertical-align: 2px !important;
}


.ant-modal-wrap {

	z-index: 1003 !important;
}


.ant-back-top {

    right: 50px !important;
}


::-webkit-scrollbar {

    width: 10px;
    height: 10px;
    background:white;
}


::-webkit-scrollbar-thumb {

    background: #ededed;
    border-radius: 3px;
}


.ant-message
{
	z-index: 9999 !important;
}


.react-grid-item.react-grid-placeholder
{
  background: #3282b8;
  opacity: 0.1;
}


.fileContainer
{
	padding: 0;
}


.ant-btn-danger
{
	background-color: rgb(255, 0, 0);
}


.ant-btn-dashed
{
	border-color: #1890ff;
}


.row
{
	align-items: center;
    margin-bottom: 10px;
}


.ant-collapse .ant-collapse-item .ant-collapse-header
{
	padding: 9px 20px 9px 35px;
}
