
.formTitle {

	font-size: 20px;
	margin-bottom: 30px;
	color: #595959;
}


.form-icon {

    font-size: 0.85em;
    margin-right: 0.5em;
}


.ant-form-item {

	margin-bottom: 0 !important;
}


.ant-form-explain {

	margin-bottom: 3px !important;
	font-size: 0.9em !important;
}


.deviceForm {

	margin-bottom: 0 !important;
}


.smallForm {

	font-size: 14px;
	margin-left: 10px;
}
