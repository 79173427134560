.activeAlarmIcon {

	-webkit-animation: blinker step-start 1.5s infinite;
}


.activeAlarmIcon:hover {

	-webkit-animation: none;
    color: rgb(255, 83, 105);
}


@keyframes blinker {

	50% {
		opacity: 0;
	}
}


[data-title].activeAlarmCard:hover::before {

  content: attr(data-title);
  width: 200px;
  position: absolute;
  bottom: 50px;
  right: -60px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 5px;
  background-color: rgba(255, 83, 105, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: pre-line;
  z-index: 9999;
}


[data-title].noAlarmCard:hover::before {

	content: attr(data-title);
    width: 200px;
    position: absolute;
	bottom: 50px;
    right: -60px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: rgba(40, 167, 69, 0.85);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
	white-space: pre-line;
	z-index: 9999;
}

.noAlarmCard{
    margin-top: -20px;
}

.activeAlarm {
    margin-top: -20px;
}