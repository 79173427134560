
.highcharts-wrapper > div {
    height: 100%;
}


.grouping-controls {
    text-align: center;
}

.grouping-controls-sliding {
    text-align: center;
	margin-top: -16px;
}


.spinContainer {
	text-align: center;
}

.date-selected {
	text-align: right;
	margin-top: -20px;
}

.shortSpan {
	display: block;
	width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.button-all-data {
	
	margin-left: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}
