#header {
	position: fixed;
	top: 0;
	width: 100%;
	min-height: 56px;
	z-index: 1001;
	margin-left: 0;
}

.image {
	height: 100px;
	width: 100px;
	font-size: 25px;
	background: blue;
	margin: 10px;
	display: inline-block;
	line-height: 100px;
}

.image-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: 0px;
	vertical-align: middle;
	display: inline-block;
	white-space: nowrap;
	overflow-x: hidden;
	overflow-y: hidden;
}

.prev,
.next {
	cursor: pointer;
	color: black;
	transition: 0.6s ease;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.next {
	margin-left: 8px !important;
	margin-right: 30px;
}

.prev:hover,
.next:hover {
	color: white;
	height: 100%;
}

.navbar {
	padding: 0 1rem !important;
}

.navbar-toggler {
	margin: 0.5rem 0 !important;
}

.navbar-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	background: #f7f7f7;
 	color: #73879c;
 	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

   
.navbar-container-mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #f7f7f7;
 	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.testStyle {
	width: 100%;
}

.ant-menu-submenu-title {
	background: transparent !important;
}

.navbar-container *:hover {
	color: rgba(115, 135, 156, 0.7) !important;
}


.navbar-brand {
	margin: auto !important;
}

.nav-link {
	padding: 0.5rem 0rem !important;
}

.scrollNav > .nav-link {
	padding-top: 15px !important;
}

.logo-container {
	font-size: 100% !important;
	margin-left:  0px !important;
	margin-right: 15px !important;
	cursor: default;
	color: #73879c !important;
	font-weight: bold !important;
}

.logo-container-menu {
	font-size: 100% !important;
	margin-left:  10px !important;
	margin-right: 15px !important;
	cursor: default;
	color: #73879c !important;
	font-weight: bold !important;
}

.appLabel {
	font-weight: 700;
}

#back {
	margin-right: 15px !important;
}

#back:hover
{
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.header-link {
	position: relative;
	top: 18%;	
	/* padding: 1rem 1.5rem !important; */
	color: white;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.active {
	background-color: transparent !important;
}

.navbar-expand-md .nav-link {
	padding-right: 0 !important;
}

.navbar-nav {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row !important;
	
}


.line-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 65%;
}

.line-header-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 65%;
	align-items: center;
	margin-left: -137px;
	margin-top: -18px;
}

.navbar-dark .navbar-nav .nav-link {
	color: #73879c !important;
}

.appConfig {
	padding: 0.5rem  !important;
	margin-top: 2px !important;
	margin-right: 15px !important;
}


#cogs{
	margin-top:  2px !important;
	margin-left: 25px !important;
}

.categoryNameDiv {
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: 1em;
	font-weight: 300;
}

.editCategoryIcon {
	vertical-align: 0.125em !important;
	margin-right: 5px !important;
	margin-left: 5px !important;
	transition: all 0.05s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.editCategoryIcon_disabled {
	vertical-align: 0.125em !important;
	margin-right: 5px !important;
	margin-left: 5px !important;
	opacity: 0.4 !important;
}

.editCategoryIcon:hover {
	cursor: pointer;
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
	border-bottom: 1px solid #fff !important;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

span,
.ant-menu-item {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.ant-menu-submenu-title {
	margin-top: 5px !important;
}

.testStyle {
	display: flex;
	justify-content: center;
}

@media (max-width: 764px) {
	.ant-menu-submenu-title > span {
		color: black !important
	}
	.appConfig {
		padding-top: 1rem !important;
		margin-right: 0px;
		margin-left: 0px;
	}
}

.positionMenu {
	position: relative;
	left: 0%;
	top: 0%;
}

.navArrow {
	color: #73879c;
}

.togglerMenuButton {
	position: relative;
	color: #73879c;
	cursor: pointer;
	font-size: 26px;
}

.buttonsMenu {
	padding-bottom: 8px;
}

.menu-toggler {
	padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: none;
}