
html {

    height: 100%;
    background: #fff;
}


body {

    height: auto !important;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background: transparent;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {

    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


blockquote {

    color: #666;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em #eee solid;
}


pre {

    background: #f1f1f1;
    border-radius: 3px;
    padding: 1em 10px;
    display: block;
    font-size: 87.5%;
    color: #212529;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}


.width100 {

    width: 100%;
}


.align-center {

    text-align: center;
}


.align-left {

    text-align: left;
}


@media only screen and (max-width: 767px) {

    .hidden-xs {
        display: none !important;
    }
}


@media only screen and (min-width: 768px) {

    .visible-xs {
        display: none !important;
    }
}
