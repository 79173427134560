
.odometer.odometer-auto-theme, .odometer.odometer-theme-car {

	color: white !important;
}


.odometer-digit {

	background-image: linear-gradient(to bottom, #666666 0%, #333333 40%, #000 60%, #333333 80%, #666666 100%);
}


.odometer.odometer-auto-theme .odometer-digit:last-child, .odometer.odometer-theme-car .odometer-digit:last-child {

	background-image: linear-gradient(to bottom, #eee0d3 0%, #eee0d3 40%, #bbaa9a 60%, #eee0d3 80%, #eee0d3 100%) !important;
}


.title-index {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    text-align: center;
}


/* .date-index {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 0%);
    left: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
} */


.odometer-theme-digital .odometer-inside, .odometer-theme-train-station .odometer-inside {
    position: relative !important;
    top: 50% !important;
    transform: translate(0, -50%) !important;
}

.image-water {
    width: 260px;
}

.image-gaz {
    width: 280px;
    z-index: 1;
}

.image-electricity {
    width: 250px;
}

.image-linky {
    width: 250px;
}

.image-gazpar {
    width: 286px;
    z-index: 1;
}

.image-heat {
    width: 210px;
}

.odometer-theme-car {
    position: relative !important;
    top: -140px !important;
    font-size: 14px !important;
}

.odometer-theme-minimal {
    position: relative !important;
    top: -79px !important;
    left: 7px !important;
    font-size: 14px !important;
    width: 140px !important;
}

.odometer-theme-digital {
    position: relative !important;
    top: -169px !important;
    left: -18px !important;
    font-size: 16px !important;
    width: 140px !important;
    height: 29px;
}

.odometer-theme-train-station {
    position: relative !important;
    top: -119px !important;
    font-size: 14px !important;
    height: 20px;
}

.odometer-theme-gazpar {
    position: relative !important;
    top: -95px !important;
    font-size: 15px !important;
    width: 150px;
    height: 30px;
    left: 10px;
}

.odometer-theme-plaza {
    font-size: 20px !important;
}

.odometer-theme-heat {
    position: relative !important;
    top: -148px !important;
    left: -1px !important;
    font-size: 13px !important;
    width: 114px !important;
   
}


.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img {
    display: flex;
    justify-content: center;
}