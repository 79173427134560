.bg-black {
}

#popSched {
    width: 190px;
}

.fc-scrollgrid {
    background-color: white;
}

