@import url(//fonts.googleapis.com/css?family=Wallpoet);
@import url(//fonts.googleapis.com/css?family=Arimo);
@import url(//fonts.googleapis.com/css?family=Wallpoet);
@import url(//fonts.googleapis.com/css?family=Arimo);
@import url(//fonts.googleapis.com/css?family=Arimo);
@import url(//fonts.googleapis.com/css?family=Arimo);
@import url(//fonts.googleapis.com/css?family=Wallpoet);
#signInTitle {

	text-align: center;
	margin-top: 1.5rem !important;
	margin-bottom: 3rem !important;
}


#signIn {

	text-align: center;
	margin-top: 3rem;
}


.signInButton {

	width: 100px;
}


.signInIcon {

	margin-right: 10px;
	font-size: 13px;
}


.loginCard {

	border-radius: 0;
	margin-top: 10%;
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}


.loginFormGroup {

	width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.centeredRow
{
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
  	
	margin: auto;
}

.credits
{
	position: absolute;
	bottom: 10px;
  	left: 50%;
	-webkit-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
	
	margin: auto;
}

.title {
    margin-top: 30px;
}


.loginLoader {

    position: absolute;
    top: 50%;
    left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}


.themeSuccessAlert {

	width: 100%;
	text-align: center;
}


.themeBuilderSpinner {

	margin-left: auto !important;
	margin-right: auto !important;
	margin-top: 20px !important;
}


.largeCol {

	padding-left: 0px !important;
	padding-right: 0px !important;
}

.config-drawer .ant-drawer-mask {
	opacity: 0 !important;
	-webkit-animation: none !important;
	        animation: none !important;
}

.ant-scrolling-effect {
	overflow : visible !important;
} 

.config-drawer .ant-drawer-content {

	padding: 10px;
}


.config-drawer .ant-drawer-wrapper-body {

	overflow-x: hidden !important;
}


.drawerButton-theme {

	width: 100%;
}


.drawerButton-category {

	width: 100%;
}


.drawerButton-left {

	width: 99%;
	margin-right: 1%;
}


.drawerButton-right {

	width: 99%;
	margin-left: 1%;
}


.drawerIcon {

	margin-right: 10px;
}


.drawerIcon-category {

	vertical-align: 0.125em !important;
    font-size: 15px !important;
}


.themeBuilderOpen {

	-webkit-animation:fadeInFromLeft 0.5s;
}


.themeBuilderHidden {

	-webkit-animation:fadeOutToRight 0.3s;
	pointer-events:none;
	transition: opacity 2s;
	opacity: 0;
}


@-webkit-keyframes fadeOutToRight {

  from {-webkit-transform: translateX(0);transform: translateX(0); opacity: 1;}
  to { -webkit-transform: translateX(300px); transform: translateX(300px); opacity: 0;}
}


@keyframes fadeOutToRight {

  from {-webkit-transform: translateX(0);transform: translateX(0); opacity: 1;}
  to { -webkit-transform: translateX(300px); transform: translateX(300px); opacity: 0;}
}


@-webkit-keyframes fadeInFromLeft {

  from {-webkit-transform: translateX(-300px);transform: translateX(-300px); opacity: 0;}
  to { -webkit-transform: translateX(0); transform: translateX(0); opacity: 1;}
}


@keyframes fadeInFromLeft {

  from {-webkit-transform: translateX(-300px);transform: translateX(-300px); opacity: 0;}
  to { -webkit-transform: translateX(0); transform: translateX(0); opacity: 1;}
}

.logout-link {
    cursor: pointer;
	color: #73879c;
}

.menu-drawer .ant-drawer-body {
    padding: 24px 0;
}

.menu-drawer .ant-menu-submenu > .ant-menu-sub .ant-menu-item {
    padding-left: 40px !important;
}
#header {
	position: fixed;
	top: 0;
	width: 100%;
	min-height: 56px;
	z-index: 1001;
	margin-left: 0;
}

.image {
	height: 100px;
	width: 100px;
	font-size: 25px;
	background: blue;
	margin: 10px;
	display: inline-block;
	line-height: 100px;
}

.image-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: 0px;
	vertical-align: middle;
	display: inline-block;
	white-space: nowrap;
	overflow-x: hidden;
	overflow-y: hidden;
}

.prev,
.next {
	cursor: pointer;
	color: black;
	transition: 0.6s ease;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.next {
	margin-left: 8px !important;
	margin-right: 30px;
}

.prev:hover,
.next:hover {
	color: white;
	height: 100%;
}

.navbar {
	padding: 0 1rem !important;
}

.navbar-toggler {
	margin: 0.5rem 0 !important;
}

.navbar-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	background: #f7f7f7;
 	color: #73879c;
 	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

   
.navbar-container-mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #f7f7f7;
 	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.testStyle {
	width: 100%;
}

.ant-menu-submenu-title {
	background: transparent !important;
}

.navbar-container *:hover {
	color: rgba(115, 135, 156, 0.7) !important;
}


.navbar-brand {
	margin: auto !important;
}

.nav-link {
	padding: 0.5rem 0rem !important;
}

.scrollNav > .nav-link {
	padding-top: 15px !important;
}

.logo-container {
	font-size: 100% !important;
	margin-left:  0px !important;
	margin-right: 15px !important;
	cursor: default;
	color: #73879c !important;
	font-weight: bold !important;
}

.logo-container-menu {
	font-size: 100% !important;
	margin-left:  10px !important;
	margin-right: 15px !important;
	cursor: default;
	color: #73879c !important;
	font-weight: bold !important;
}

.appLabel {
	font-weight: 700;
}

#back {
	margin-right: 15px !important;
}

#back:hover
{
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

.header-link {
	position: relative;
	top: 18%;	
	/* padding: 1rem 1.5rem !important; */
	color: white;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.active {
	background-color: transparent !important;
}

.navbar-expand-md .nav-link {
	padding-right: 0 !important;
}

.navbar-nav {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row !important;
	
}


.line-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 65%;
}

.line-header-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 65%;
	align-items: center;
	margin-left: -137px;
	margin-top: -18px;
}

.navbar-dark .navbar-nav .nav-link {
	color: #73879c !important;
}

.appConfig {
	padding: 0.5rem  !important;
	margin-top: 2px !important;
	margin-right: 15px !important;
}


#cogs{
	margin-top:  2px !important;
	margin-left: 25px !important;
}

.categoryNameDiv {
	position: absolute;
	left: 50%;
	-webkit-transform: translatex(-50%);
	        transform: translatex(-50%);
	top: 1em;
	font-weight: 300;
}

.editCategoryIcon {
	vertical-align: 0.125em !important;
	margin-right: 5px !important;
	margin-left: 5px !important;
	transition: all 0.05s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.editCategoryIcon_disabled {
	vertical-align: 0.125em !important;
	margin-right: 5px !important;
	margin-left: 5px !important;
	opacity: 0.4 !important;
}

.editCategoryIcon:hover {
	cursor: pointer;
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
	border-bottom: 1px solid #fff !important;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

span,
.ant-menu-item {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.ant-menu-submenu-title {
	margin-top: 5px !important;
}

.testStyle {
	display: flex;
	justify-content: center;
}

@media (max-width: 764px) {
	.ant-menu-submenu-title > span {
		color: black !important
	}
	.appConfig {
		padding-top: 1rem !important;
		margin-right: 0px;
		margin-left: 0px;
	}
}

.positionMenu {
	position: relative;
	left: 0%;
	top: 0%;
}

.navArrow {
	color: #73879c;
}

.togglerMenuButton {
	position: relative;
	color: #73879c;
	cursor: pointer;
	font-size: 26px;
}

.buttonsMenu {
	padding-bottom: 8px;
}

.menu-toggler {
	padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: none;
}

.widget-icon:hover 
{

	-webkit-transform: scale(1.1) !important;
			transform: scale(1.1) !important;
}

.group 
{

	display: inline-block;
}

.nameRefresh 
{

	display: 'flex';
	justify-content: space-between;
}


.widget-icon:hover 
{

	-webkit-transform: scale(1.1) !important;
			transform: scale(1.1) !important;
}

.group 
{

	display: inline-block;
}

.nameRefresh 
{

	display: 'flex';
	justify-content: space-between;
}


.tableContainer {
	width: 100%;
	padding-left: 15px;
    padding-right: 15px;
}


.editable-cell {
    position: relative;
}


.editable-cell-value-wrap {
	padding: 5px 12px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
}

/* --- */
.verticalDivider
{
	width: 1px;
	border: none;
	border-left: 1px solid;
	color: #D3D3D3;
}
.tableContainer {
	width: 100%;
	padding-left: 15px;
    padding-right: 15px;
    overflow: scroll;
}

.html-textarea
{
    height: 250px !important;
}

.drafjs-editor
{
    border: 1px solid #f1f1f1;
    border-radius: 2px;
}

.DraftEditor-content
{
    height: 250px;
}

/* NOTE: the order of these styles DO matter */

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #26B99A;
    border: 1px solid #169F85;
    color: white;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
    background: #169F85;
    color: white;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
    background: #169F85;
    color: white;
}

/* Will edit when the second date (end date) in a range of dates */
/* is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #26B99A;
}




.geo-tracking-table-container
{
	width: 100%;
}

.editable-cell
{
    position: relative;
}

.editable-cell-value-wrap
{
	padding: 5px 12px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
}

/* --- */
.iconPicker-disabled
{
	cursor: not-allowed !important;
	opacity: 0.5;
	fill: grey !important;
}

.alignInput {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tableContainer {
	width: 100%;
	padding-left: 15px;
    padding-right: 15px;
}


.editable-cell {
    position: relative;
}


.editable-cell-value-wrap {
	padding: 5px 12px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
}

.rc-color-picker-trigger {

	width: 20px !important;
	vertical-align: middle !important;
	border-radius: 20px !important;
	border: 1px solid #949494 !important;
	box-shadow: none !important;
	margin-right: 10px;
	margin-left: 5px;
}


.formPHIcon {

    vertical-align: 0.125em !important;
	margin-right: 5px !important;
}


.chooseFileButton {

	border-radius: 5px !important;
}


.modal-header {

    border-bottom: 0 !important;
}


.modal-footer {

    border-top: 0 !important;
}

.confButton {
	bottom: 50px;
	right: 80px;
    
	cursor: pointer;
	height: 45px !important;
	width: 45px !important;
	opacity: 0.5;
	color: #329CC3;
    
	border-radius: 70px;
	background-color: transparent;
	border-width: 0 !important;
	cursor:pointer;
    
	transition: .2s ease-in;
	-webkit-animation: blink 2s;
	        animation: blink 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.smallConfButton {
    height: 35px !important;
    width: 35px !important;
}

.HTML_CONTENT {
    flex-grow: 1;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-train-station {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  top: 140%;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-train-station .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-train-station .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-train-station .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-train-station .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-train-station .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-train-station .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer-value {
  text-align: center;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-train-station .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-train-station.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-train-station {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhiZjVhNSIgc3RvcC1vcGFjaXR5PSIwLjQiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: radial-gradient(rgba(139, 245, 165, 0.4), #000000);
  background-color: #000;
  font-family: "Wallpoet", monospace;
  padding: 0 0.2em;
  line-height: 1.1em;
  color: #ffffff;
}

.odometer.odometer-auto-theme .odometer-digit + .odometer-digit, .odometer.odometer-theme-train-station .odometer-digit + .odometer-digit {
  margin-left: 0.1em;
}
.odometer.odometer-auto-theme, .odometer.odometer-theme-car {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-car .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-car .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-car .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-car .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-car .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-car .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-car {
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #000;
  color: #ffffff !important;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-car .odometer-digit {
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: linear-gradient(to bottom, #333333 0%, #333333 40%, #101010 60%, #333333 80%, #333333 100%);
  padding: 0 0.15em;
}


.odometer.odometer-auto-theme .odometer-digit:first-child, .odometer.odometer-theme-car .odometer-digit:first-child {
  border-radius: 0.2em 0 0 0.2em;
}


.odometer.odometer-theme-car .odometer-digit:nth-last-child(4),
.odometer.odometer-theme-car .odometer-digit:nth-last-child(3),
.odometer.odometer-theme-car .odometer-digit:nth-last-child(2) {
  background-image: linear-gradient(to bottom, #ff1100 0%, #ff1100 40%, #ff1100 60%, #ff1100 80%, #ff1100 100%);
  background-color: #ff0000;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition-timing-function: linear;
}


.odometer-inside > .odometer-digit:nth-child(1) {
  display: none !important
}


.odometer-inside > .odometer-digit:nth-last-child(1) {
  display: none !important
}
.odometer.odometer-auto-theme, .odometer.odometer-theme-digital {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  top: 140%;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-digital .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-digital .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-digital .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-digital .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-digital .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-digital .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer-value {
  text-align: center;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-digital .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-digital.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-digital {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhiZjVhNSIgc3RvcC1vcGFjaXR5PSIwLjQiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: radial-gradient(rgba(139, 245, 165, 0.4), #000000);
  background-color: #000;
  font-family: "Wallpoet", monospace;
  padding: 0 0.2em;
  line-height: 1.1em;
  color: #ffffff;
}

.odometer.odometer-auto-theme .odometer-digit + .odometer-digit, .odometer.odometer-theme-digital .odometer-digit + .odometer-digit {
  margin-left: 0.1em;
}
.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  display: inline-block;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #000;
  color: #ffffff !important;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: linear-gradient(to bottom, #333333 0%, #333333 40%, #101010 60%, #333333 80%, #333333 100%);
  padding: 0 0.15em;
}

.odometer-theme-minimal .odometer-digit:nth-last-child(4), 
.odometer-theme-minimal .odometer-digit:nth-last-child(3),
.odometer-theme-minimal .odometer-digit:nth-last-child(2) {
  background-image: linear-gradient(to bottom, #ff1100 0%, #ff1100 40%, #ff1100 60%, #ff1100 80%, #ff1100 100%);
  background-color: #ff0000;
}


.odometer.odometer-auto-theme .odometer-digit:first-child, .odometer.odometer-theme-minimal .odometer-digit:first-child {
  border-radius: 0.2em 0 0 0.2em;
}




.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition-timing-function: linear;
}


.odometer-inside > .odometer-digit:nth-child(1) {
  display: none !important
}


.odometer-inside > .odometer-digit:nth-last-child(1) {
  display: none !important
}
.odometer.odometer-auto-theme, .odometer.odometer-theme-plaza {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-plaza .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-plaza .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-plaza .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-plaza .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-plaza .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-plaza .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-plaza .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-plaza.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-plaza.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-plaza.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-plaza.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-plaza {
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #000;
  color: #ffffff !important;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-plaza .odometer-digit {
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: linear-gradient(to bottom, #333333 0%, #333333 40%, #101010 60%, #333333 80%, #333333 100%);
  padding: 0 0.15em;
}


.odometer.odometer-auto-theme .odometer-digit:first-child, .odometer.odometer-theme-plaza .odometer-digit:first-child {
  border-radius: 0.2em 0 0 0.2em;
}


.odometer.odometer-theme-plaza .odometer-digit:nth-last-child(4),
.odometer.odometer-theme-plaza .odometer-digit:nth-last-child(3),
.odometer.odometer-theme-plaza .odometer-digit:nth-last-child(2) {
  background-image: linear-gradient(to bottom, #ff1100 0%, #ff1100 40%, #ff1100 60%, #ff1100 80%, #ff1100 100%);
  background-color: #ff0000;
  color: rgb(255, 255, 255);
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-plaza .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-plaza.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-car.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition-timing-function: linear;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-gazpar {
  display: inline-block;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-gazpar .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-gazpar .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-gazpar .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-gazpar .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-gazpar .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-gazpar .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-gazpar .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-gazpar.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-gazpar.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-gazpar.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-gazpar.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-gazpar {
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #000;
  color: #ffffff !important;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-gazpar .odometer-digit {
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: linear-gradient(to bottom, #333333 0%, #333333 40%, #101010 60%, #333333 80%, #333333 100%);
  padding: 0 0.15em;
}

.odometer-theme-gazpar .odometer-digit:nth-last-child(4), 
.odometer-theme-gazpar .odometer-digit:nth-last-child(3),
.odometer-theme-gazpar .odometer-digit:nth-last-child(2) {
  background-image: linear-gradient(to bottom, #ff1100 0%, #ff1100 40%, #ff1100 60%, #ff1100 80%, #ff1100 100%);
  background-color: #ff0000;
}


.odometer.odometer-auto-theme .odometer-digit:first-child, .odometer.odometer-theme-gazpar .odometer-digit:first-child {
  border-radius: 0.2em 0 0 0.2em;
}




.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-gazpar .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-gazpar.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-gazpar.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition-timing-function: linear;
}


.odometer-inside > .odometer-digit:nth-child(1) {
  display: none !important
}


.odometer-inside > .odometer-digit:nth-last-child(1) {
  display: none !important
}
.odometer.odometer-auto-theme, .odometer.odometer-theme-heat {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  top: 140%;
}


.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-heat .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-heat .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-heat .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-heat .odometer-digit .odometer-ribbon {
  display: block;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-heat .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}


.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-heat .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer-value {
  text-align: center;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-heat .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}


.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-heat.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}


.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-heat.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-heat.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}


.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-heat.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-heat {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzhiZjVhNSIgc3RvcC1vcGFjaXR5PSIwLjQiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: radial-gradient(rgba(139, 245, 165, 0.4), #000000);
  background-color: #000;
  font-family: "Wallpoet", monospace;
  padding: 0 0.2em;
  line-height: 1.1em;
  color: #ffffff;
}

.odometer.odometer-auto-theme .odometer-digit + .odometer-digit, .odometer.odometer-theme-heat .odometer-digit + .odometer-digit {
  margin-left: 0.1em;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-car {

	color: white !important;
}


.odometer-digit {

	background-image: linear-gradient(to bottom, #666666 0%, #333333 40%, #000 60%, #333333 80%, #666666 100%);
}


.odometer.odometer-auto-theme .odometer-digit:last-child, .odometer.odometer-theme-car .odometer-digit:last-child {

	background-image: linear-gradient(to bottom, #eee0d3 0%, #eee0d3 40%, #bbaa9a 60%, #eee0d3 80%, #eee0d3 100%) !important;
}


.title-index {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    text-align: center;
}


/* .date-index {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 0%);
    left: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
} */


.odometer-theme-digital .odometer-inside, .odometer-theme-train-station .odometer-inside {
    position: relative !important;
    top: 50% !important;
    -webkit-transform: translate(0, -50%) !important;
            transform: translate(0, -50%) !important;
}

.image-water {
    width: 260px;
}

.image-gaz {
    width: 280px;
    z-index: 1;
}

.image-electricity {
    width: 250px;
}

.image-linky {
    width: 250px;
}

.image-gazpar {
    width: 286px;
    z-index: 1;
}

.image-heat {
    width: 210px;
}

.odometer-theme-car {
    position: relative !important;
    top: -140px !important;
    font-size: 14px !important;
}

.odometer-theme-minimal {
    position: relative !important;
    top: -79px !important;
    left: 7px !important;
    font-size: 14px !important;
    width: 140px !important;
}

.odometer-theme-digital {
    position: relative !important;
    top: -169px !important;
    left: -18px !important;
    font-size: 16px !important;
    width: 140px !important;
    height: 29px;
}

.odometer-theme-train-station {
    position: relative !important;
    top: -119px !important;
    font-size: 14px !important;
    height: 20px;
}

.odometer-theme-gazpar {
    position: relative !important;
    top: -95px !important;
    font-size: 15px !important;
    width: 150px;
    height: 30px;
    left: 10px;
}

.odometer-theme-plaza {
    font-size: 20px !important;
}

.odometer-theme-heat {
    position: relative !important;
    top: -148px !important;
    left: -1px !important;
    font-size: 13px !important;
    width: 114px !important;
   
}


.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img {
    display: flex;
    justify-content: center;
}
.CLASSIC_VALUE_CONTENT, .BOOLEAN_CONTENT, .INDEX_CONTENT {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-grow: 1;
}


.value-content {
    flex-grow: 1;
}


.title {

    font-size: 1.2em;
    font-weight: bold;
}


.value {
    flex-grow: 1;
    font-size: 1.5em;
	margin: 15px 0 15px 0;
}


.date {

    font-style: italic;
}

.DWG_DIS_COL-2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
}

.DWG_DIS_COL-3 {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
}

.highcharts-wrapper > div {
    height: 100%;
}


.grouping-controls {
    text-align: center;
}

.grouping-controls-sliding {
    text-align: center;
	margin-top: -16px;
}


.spinContainer {
	text-align: center;
}

.date-selected {
	text-align: right;
	margin-top: -20px;
}

.shortSpan {
	display: block;
	width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.button-all-data {
	
	margin-left: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}


.ant-calendar-picker-container {
 z-index: 10000 !important;
}
.valueCard
{
	box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.15);
}

.valueCard:hover
{
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.inactiveDeviceMarkerIcon
{
    position: absolute;
    cursor: pointer;
	z-index: 1000; /*red (inactive) devices appear on top */
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}


.activeDeviceMarkerIcon
{
    position: absolute;
    cursor: pointer;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}


.unavailableDeviceMarkerIcon
{
    position: absolute;
    cursor: pointer;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}


.valueSnippetDiv
{
    position: absolute;
    top: 12.5px;
    left: 48px;
	max-width: 65px;
	height: 20px;
	padding: 5px;
	border-radius: 5px;
	line-height: 100%;
	text-align: center;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
	color: black;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
	font: 400 11px Roboto, Arial, sans-serif;
}


.formTitle {

	font-size: 20px;
	margin-bottom: 30px;
	color: #595959;
}


.form-icon {

    font-size: 0.85em;
    margin-right: 0.5em;
}


.ant-form-item {

	margin-bottom: 0 !important;
}


.ant-form-explain {

	margin-bottom: 3px !important;
	font-size: 0.9em !important;
}


.deviceForm {

	margin-bottom: 0 !important;
}


.smallForm {

	font-size: 14px;
	margin-left: 10px;
}



.greenRow {
	color: rgb(90, 202, 115);
}


.redRow {
	color: rgb(255, 83, 105);
}

.i {
	color: #6dc8df
}

.flash {
	color: red !important;
	cursor: pointer;
	width: 0 !important;
}


@-webkit-keyframes blinker {
	50% {
		opacity: 0;
	}
}


@keyframes blinker {
	50% {
		opacity: 0;
	}
}


.tableTooltip {
	height: 0 !important;
}


.components-table-demo-control-bar {
	margin-bottom: 5px;
}


.greyIcon {
	color: rgb(142, 142, 142);
	vertical-align: 0.1em;
	margin-right: 10px;
}


.greyIcon-no-margin {
	color: rgb(196, 196, 196);
	vertical-align: 0.1em;
}


#popoverAlarms {
	overflow-y:auto;
	max-height:164px;
}

.ant-table {
	background-color: white;
}
#status-text  {
	margin-top: 10px;
    text-align: center;
}

.fa-gradient  {
	background: -webkit-gradient(linear, left top, left bottom, from(#f00), to(#333));
	-webkit-text-fill-color: transparent;
}

.STATUS_CONTENT {
	height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
	flex-grow: 1;
}

.status-content {
	flex-grow: 1;
}



#underlay {
    display: inline-block;
    position: relative;
}

#underlay > .markers {
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
}

#underlay > .markers > .marker {
    position: absolute;
	cursor: pointer;
	color: #26B99A;
	border: 0;
	height: 45px;
	width: 75px;

	-webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}

#underlay > .markers > .marker:hover {
	-webkit-transform: translateX(-50%) translateY(-50%) scale(1.1);
			transform: translateX(-50%) translateY(-50%) scale(1.1);

	z-index: 1000;
}

.markerBeingPlaced {
	color: #329CC3;

	position: absolute;
	height: 30px;
	width: 30px;

	-webkit-animation: blinkMarkerBeingPlaced 2.5s;

	        animation: blinkMarkerBeingPlaced 2.5s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.popup-content {
	background: rgba(255, 255, 255, 0.7) !important;
	border-radius: 20px !important;
}

@-webkit-keyframes blink
{
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes blink
{
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes blinkMarkerBeingPlaced {
	0% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1);
	            transform: translateX(-50%) translateY(-70%) scale(1);
	}
	50% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1.5);
	            transform: translateX(-50%) translateY(-70%) scale(1.5);
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1);
	            transform: translateX(-50%) translateY(-70%) scale(1);
	}
}

@keyframes blinkMarkerBeingPlaced {
	0% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1);
	            transform: translateX(-50%) translateY(-70%) scale(1);
	}
	50% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1.5);
	            transform: translateX(-50%) translateY(-70%) scale(1.5);
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-70%) scale(1);
	            transform: translateX(-50%) translateY(-70%) scale(1);
	}
}

.addButton {
	position: absolute;
	bottom: 50px;
	right: 80px;

	cursor: pointer;
	height: 45px !important;
	width: 45px !important;
	opacity: 0.5;
	color: #329CC3;

	border-radius: 70px;
	background-color: transparent;
	border-width: 0 !important;
	cursor:pointer;

	transition: .2s ease-in;
	-webkit-animation: blink 2s;
	        animation: blink 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.addButton:hover {
	opacity: 1;

	-webkit-animation-play-state:paused;
	animation-play-state:paused;
}

.smallAddButton {
	height: 35px !important;
	width: 35px !important;
}

.marker-container {
    overflow: hidden;
}

.sitemap-content {
	text-align: center;
}


[data-title].inactiveDeviceCard:hover::before {

  content: attr(data-title);
  width: 200px;
  position: absolute;
  bottom: 50px;
  right: -60px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 5px;
  background-color: rgba(255, 83, 105, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: pre-line;
}


[data-title].activeDeviceCard:hover::before {

	content: attr(data-title);
    width: 200px;
    position: absolute;
	bottom: 50px;
    right: -60px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: rgba(40, 167, 69, 0.85);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
	white-space: pre-line;
}


.configStatusMarker {

	margin: auto;
}


.viewStatusMarker {

	margin: auto;
	background: white;
	border-radius: 30px;
	padding: 4px;
	font-size: 25px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(.25, .8, .25, 1);
}


.viewStatusMarker:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}


.sitemapValueSnippetDiv
{
    position: absolute;
	max-width: 65px;
	height: 20px;
	padding: 5px;
	border-radius: 5px;
	line-height: 100%;
	text-align: center;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
	color: black;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
	font: 400 9px Roboto, Arial, sans-serif;
}


.deviceMarkerSpan
{
	position: absolute;
	left: 25px;
	top: 18px;
}


.deviceMarkerSpanView{
    position: absolute;
	background-color: rgba(255, 255, 255, 0.95);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}

.activeAlarmIcon {

	-webkit-animation: blinker step-start 1.5s infinite;
}


.activeAlarmIcon:hover {

	-webkit-animation: none;
    color: rgb(255, 83, 105);
}


@-webkit-keyframes blinker {

	50% {
		opacity: 0;
	}
}


@keyframes blinker {

	50% {
		opacity: 0;
	}
}


[data-title].activeAlarmCard:hover::before {

  content: attr(data-title);
  width: 200px;
  position: absolute;
  bottom: 50px;
  right: -60px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 5px;
  background-color: rgba(255, 83, 105, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: pre-line;
  z-index: 9999;
}


[data-title].noAlarmCard:hover::before {

	content: attr(data-title);
    width: 200px;
    position: absolute;
	bottom: 50px;
    right: -60px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: rgba(40, 167, 69, 0.85);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
	white-space: pre-line;
	z-index: 9999;
}

.noAlarmCard{
    margin-top: -20px;
}

.activeAlarm {
    margin-top: -20px;
}
.sendCommandButton:hover {

	-webkit-transform: scale(1.1);
			transform: scale(1.1);
}


.disabledSendCommandButton {

	color: rgb(245, 245, 245) !important;
	cursor: not-allowed !important;
}

.COMMAND_CONTENT {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.bg-black {
}

#popSched {
    width: 190px;
}

.fc-scrollgrid {
    background-color: white;
}



#status-text 
{
	margin-top: 10px;
    text-align: center;
}

.fa-gradient 
{
	background: -webkit-gradient(linear, left top, left bottom, from(#f00), to(#333));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

#video {
	margin-top: 30px;
}

#buttons {
	position: absolute;
	bottom:   0;
	left: 0;
	right: 0;
	padding-bottom: 10px;
	
}

#buttons > .ant-switch{
	margin-left: 80px;
	margin-right: 80px;
}
.widget {
    color: #73879C;
    height: 100%;
}


.widget-container {

	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}


.widget-container:hover {

	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}


.widget-config {
	padding-bottom: 15px;
    text-align: right;
	/* text-overflow: ellipsis;
	overflow: hidden; */
	white-space: nowrap;
}

.widget-block {
	padding-bottom: 15px;
    text-align: right;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.widget-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.widget-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.left-title {
    flex-direction: row;
    align-items: center;
}

.left-title .HEADER {
    padding-right: 5px;
}

.center-title .HEADER
{
    text-align: center;
}

.sitemap {
	padding: 0 !important;
}

.widget-title {
    text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
    font-weight: 450;
}


.widget-icon {

    font-size: 0.85em;
    margin-right: 0.5em;
    cursor: pointer;
    font-weight: 450;
}


.height-100 {

    height: 100%;
}


.modal-content {

    padding: 5px;
}


.wideModal {
    max-width: 60% !important;
}


.wideModal .modal-content {
    padding: 20px !important;
}


.fadedOutFormGroup {
    opacity: 0.6;
}


.fadedOutFormGroup:hover {
    opacity: 1;
}


.ant-notification {
	z-index: 9999;
}


.ant-popover {
	z-index: 9999;
}


.noData {
	text-align: center;

	-webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}


.ant-btn .anticon {
    vertical-align: 0.125em;
}


.shadowedIcon {
	border-color: transparent !important;
}


.shadowedIcon:hover, .shadowedIcon-selected {
	border-color: transparent !important;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}


.shadowedIcon:hover {
	transform: scale(1.2);
	-ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
}


@-webkit-keyframes pulsate {
	from { opacity: 1; }
    50% { opacity: 0.05; }
    to { opacity: 1; }
}


@keyframes pulsate {
	from { opacity: 1; }
    50% { opacity: 0.05; }
    to { opacity: 1; }
}


.pulsating-tag {
	transition: .3s ease-in;
	-webkit-animation: pulsate 2s;
	        animation: pulsate 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}


.ant-popover-inner-content {
	overflow-y: auto;
	max-height: 80vh;
}

#toolBar {

  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 1000;
}


.toolbar-item {

    font-size: 1.05em;
    margin-left: 1em;
    cursor: pointer;
    background: transparent;
	color: white;
    padding: 0.2em 0.75em;
    border-radius: 5px;
}


.toolbar-item:hover {

	-webkit-transform: scale(1.2);
			transform: scale(1.2);
}


.toolbar-item-mobile {

    cursor: pointer;
}


.toolbar-container {

    padding: 0.5rem 1rem !important;
	background: linear-gradient(to right, #3282b8, #38c6bd);
 	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}


.toolbar-separator {

    font-size: 1.2em;
    margin-left: 1em;
    cursor: default;
    font-weight: 600;
    padding-top: 3px;
}


.toolbar-separator-mobile {

    background: #515356;
    height: 1px;
    width: 100%;
}


#actionIcons {

	margin: auto !important;
}


#refreshAll {

	margin-left: -25px !important;
	margin-right: 0 !important;
	width: 0 !important;
}

#noAccessCard {
    padding: 0px;
}



.centeredRow
{
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
  	
	margin: auto;
}

.credits
{
	position: absolute;
	bottom: 10px;
  	left: 50%;
	-webkit-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
	
	margin: auto;
}
.config-bar-item {

    color: #fff;
    cursor: pointer;
}


.widget-container {

    overflow: hidden;
}


.modalButton {

	width: 130px;
}


.ant-tooltip-inner {

	text-align: center !important;
    display: block;
    border-radius: 5px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 12px;
    font-family: sans-serif;
	white-space: pre-line;
}


.ant-tooltip
{
	z-index: 9999;
}


.ant-popover-buttons {

    text-align: center !important;
}


.pageLoader {

    position: absolute;
    top: 48%;
    left: 48%;
}


.ant-menu {

	background: transparent !important;
	border-bottom: 0;
}


.ant-menu-submenu > .ant-menu {

    padding-bottom: 5px;
}


.ant-menu-item, .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {

	top: -.5px !important;
}


.ant-menu-item, .ant-menu-submenu-title {

	padding: 0 15px !important;
}


.menuIcon {

	vertical-align: 2px !important;
}


.ant-modal-wrap {

	z-index: 1003 !important;
}


.ant-back-top {

    right: 50px !important;
}


::-webkit-scrollbar {

    width: 10px;
    height: 10px;
    background:white;
}


::-webkit-scrollbar-thumb {

    background: #ededed;
    border-radius: 3px;
}


.ant-message
{
	z-index: 9999 !important;
}


.react-grid-item.react-grid-placeholder
{
  background: #3282b8;
  opacity: 0.1;
}


.fileContainer
{
	padding: 0;
}


.ant-btn-danger
{
	background-color: rgb(255, 0, 0);
}


.ant-btn-dashed
{
	border-color: #1890ff;
}


.row
{
	align-items: center;
    margin-bottom: 10px;
}


.ant-collapse .ant-collapse-item .ant-collapse-header
{
	padding: 9px 20px 9px 35px;
}


html {

    height: 100%;
    background: #fff;
}


body {

    height: auto !important;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background: transparent;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {

    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


blockquote {

    color: #666;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em #eee solid;
}


pre {

    background: #f1f1f1;
    border-radius: 3px;
    padding: 1em 10px;
    display: block;
    font-size: 87.5%;
    color: #212529;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}


.width100 {

    width: 100%;
}


.align-center {

    text-align: center;
}


.align-left {

    text-align: left;
}


@media only screen and (max-width: 767px) {

    .hidden-xs {
        display: none !important;
    }
}


@media only screen and (min-width: 768px) {

    .visible-xs {
        display: none !important;
    }
}

