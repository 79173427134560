.config-drawer .ant-drawer-mask {
	opacity: 0 !important;
	animation: none !important;
}

.ant-scrolling-effect {
	overflow : visible !important;
} 

.config-drawer .ant-drawer-content {

	padding: 10px;
}


.config-drawer .ant-drawer-wrapper-body {

	overflow-x: hidden !important;
}


.drawerButton-theme {

	width: 100%;
}


.drawerButton-category {

	width: 100%;
}


.drawerButton-left {

	width: 99%;
	margin-right: 1%;
}


.drawerButton-right {

	width: 99%;
	margin-left: 1%;
}


.drawerIcon {

	margin-right: 10px;
}


.drawerIcon-category {

	vertical-align: 0.125em !important;
    font-size: 15px !important;
}


.themeBuilderOpen {

	-webkit-animation:fadeInFromLeft 0.5s;
}


.themeBuilderHidden {

	-webkit-animation:fadeOutToRight 0.3s;
	pointer-events:none;
	transition: opacity 2s;
	opacity: 0;
}


@keyframes fadeOutToRight {

  from {transform: translateX(0); opacity: 1;}
  to { transform: translateX(300px); opacity: 0;}
}


@keyframes fadeInFromLeft {

  from {transform: translateX(-300px); opacity: 0;}
  to { transform: translateX(0); opacity: 1;}
}
