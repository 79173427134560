
.greenRow {
	color: rgb(90, 202, 115);
}


.redRow {
	color: rgb(255, 83, 105);
}

.i {
	color: #6dc8df
}

.flash {
	color: red !important;
	cursor: pointer;
	width: 0 !important;
}


@keyframes blinker {
	50% {
		opacity: 0;
	}
}


.tableTooltip {
	height: 0 !important;
}


.components-table-demo-control-bar {
	margin-bottom: 5px;
}


.greyIcon {
	color: rgb(142, 142, 142);
	vertical-align: 0.1em;
	margin-right: 10px;
}


.greyIcon-no-margin {
	color: rgb(196, 196, 196);
	vertical-align: 0.1em;
}


#popoverAlarms {
	overflow-y:auto;
	max-height:164px;
}

.ant-table {
	background-color: white;
}