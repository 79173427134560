.sendCommandButton:hover {

	-webkit-transform: scale(1.1);
	   -moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		 -o-transform: scale(1.1);
			transform: scale(1.1);
}


.disabledSendCommandButton {

	color: rgb(245, 245, 245) !important;
	cursor: not-allowed !important;
}

.COMMAND_CONTENT {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}
