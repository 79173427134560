
.widget-icon:hover 
{

	-webkit-transform: scale(1.1) !important;
	   -moz-transform: scale(1.1) !important;
		-ms-transform: scale(1.1) !important;
		 -o-transform: scale(1.1) !important;
			transform: scale(1.1) !important;
}

.group 
{

	display: inline-block;
}

.nameRefresh 
{

	display: 'flex';
	justify-content: space-between;
}
