
.inactiveDeviceMarkerIcon
{
    position: absolute;
    cursor: pointer;
	z-index: 1000; /*red (inactive) devices appear on top */
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}


.activeDeviceMarkerIcon
{
    position: absolute;
    cursor: pointer;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}


.unavailableDeviceMarkerIcon
{
    position: absolute;
    cursor: pointer;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
}


.valueSnippetDiv
{
    position: absolute;
    top: 12.5px;
    left: 48px;
	max-width: 65px;
	height: 20px;
	padding: 5px;
	border-radius: 5px;
	line-height: 100%;
	text-align: center;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
	color: black;
	box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
	font: 400 11px Roboto, Arial, sans-serif;
}
