
.title {
    margin-top: 30px;
}


.loginLoader {

    position: absolute;
    top: 50%;
    left: 50%;
	transform: translate(-50%, -50%);
}
