#status-text  {
	margin-top: 10px;
    text-align: center;
}

.fa-gradient  {
	background: -webkit-gradient(linear, left top, left bottom, from(#f00), to(#333));
	-webkit-text-fill-color: transparent;
}

.STATUS_CONTENT {
	height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
	flex-grow: 1;
}

.status-content {
	flex-grow: 1;
}
