


.centeredRow
{
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
  	
	margin: auto;
}

.credits
{
	position: absolute;
	bottom: 10px;
  	left: 50%;
	transform: translate(-50%, 0);
	
	margin: auto;
}